import type {ReactElement} from 'react';
import React, {useRef, useEffect, useState} from 'react';
import {MODALS_IDS} from '@Components/modal-container/modal-container.types';
import {closeModal} from '@Components/modal-container/modal-container-reducer';
import type {ModalRenderProps} from './modal-render.types';
import {useAppDispatch, useAppSelector} from '@/hooks';

export function ModalRender({modalId}: ModalRenderProps): ReactElement {
  const dispatch = useAppDispatch();
  const isLoading = useRef(false);
  const [panelModule, setPanelModule] = useState<any>(null);
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[modalId];
  });

  useEffect(() => {
    if (modalData.isOpen) {
      void loadModal();
    }
  }, [modalData.isOpen]);

  const loadModal = async (): Promise<void> => {
    if (isLoading.current || panelModule) {
      return;
    }
    isLoading.current = true;
    try {
      const module: ReactElement | null = await importModalForId(modalId);
      setPanelModule(module);
    } catch (e) {
      dispatch(closeModal(modalId));
      console.error(e);
    } finally {
      isLoading.current = false;
    }
  };

  if (!panelModule) {
    return <div />;
  }

  return <panelModule.default />;
}

const importModalForId = async (modalId: string): Promise<any> => {
  switch (modalId) {
    case MODALS_IDS.CROPPER_MODAL:
      return import('@Modals/cropper-modal/cropper-modal');
    case MODALS_IDS.USER_MENU_MODAL:
      return import('@Modals/user-menu-modal/user-menu-modal');
    case MODALS_IDS.TRIM_AUDIO_MODAL:
      return import('@Modals/trim-audio-modal/trim-audio-modal');
    case MODALS_IDS.TRIM_VIDEO_MODAL:
      return import('@Modals/trim-video-modal/trim-video-modal');
    case MODALS_IDS.CONFIRMATION_MODAL:
      return import('@Modals/confirmation-modal/confirmation-modal');
    case MODALS_IDS.COMPLETE_SIGNUP_MODAL:
      return import('@Modals/complete-signup-modal/complete-signup-modal');
    case MODALS_IDS.CONFIRM_REMOVE_VIDEO_BACKGROUND_MODAL:
      return import('@Modals/confirm-remove-video-background-modal/confirm-remove-video-background-modal');
    case MODALS_IDS.POSTER_ITEMS_LIST_MODAL:
      return import('@Modals/poster-items-list-modal/poster-items-list-modal');
    case MODALS_IDS.ERROR_MODAL:
      return import('@Modals/error-modal/error-modal');
    case MODALS_IDS.CONFIRM_DELETE_MODAL:
      return import('@Modals/confirm-delete-modal/confirm-delete-modal');
    case MODALS_IDS.MESSAGE_MODAL:
      return import('@Modals/message-modal/message-modal');
    case MODALS_IDS.UPLOADING_IMAGES_MODAL:
      return import('@Modals/uploading-images-modal/uploading-images-modal');
    case MODALS_IDS.SAVE_CONFLICT_MODAL:
      return import('@Modals/save-conflict-modal/save-conflict-modal');
    case MODALS_IDS.AUDIO_TYPE_MODAL:
      return import('@Modals/audio-type-modal/audio-type-modal');
    case MODALS_IDS.USER_TABLE_MODAL:
      return import('@Modals/user-table-modal/user-table-modal');
    case MODALS_IDS.USER_SCHEDULE_MODAL:
      return import('@Modals/user-schedule-modal/user-schedule-modal');
    case MODALS_IDS.MORE_OPTIONS_MODAL:
      return import('@Modals/more-options-modal/more-options-modal');
    case MODALS_IDS.COLLECTION_ITEM_MORE_OPTIONS_MODAL:
      return import('@Modals/collection-item-more-options-modal/collection-item-more-options-modal');
    case MODALS_IDS.AUDIO_ITEM_RENAME_MODAL:
      return import('@Modals/audio-item-rename-modal/audio-item-rename-modal');
    case MODALS_IDS.ROYALTY_FILTERS_MODAL:
      return import('@Modals/royalty-filters-modal/royalty-filters-modal');
    case MODALS_IDS.EVENT_FILTERS_MODAL:
      return import('@Modals/event-filters-modal/event-filters-modal');
    case MODALS_IDS.EVENT_AREA_FILTERS_MODAL:
      return import('@Modals/event-area-filters-modal/event-area-filters-modal');
    case MODALS_IDS.CREATE_EVENT_MODAL:
      return import('@Modals/create-event-modal/create-event-modal');
    case MODALS_IDS.EDIT_EVENT_MODAL:
      return import('@Modals/edit-event-modal/edit-event-modal');
    case MODALS_IDS.COLOR_BACKGROUND_MODAL:
      return import('@Modals/color-background-modal/color-background-modal');
    case MODALS_IDS.POSTER_IN_GENERATION_MODAL:
      return import('@Modals/poster-in-generation-warning-modal/poster-in-generation-warning-modal');
    case MODALS_IDS.RESIZE_MODAL:
      return import('@Modals/resize-modal/resize-modal');
    case MODALS_IDS.MASKING_MODAL:
      return import('@Modals/masking-modal/masking-modal');
    case MODALS_IDS.CHANGE_PLAN_MODAL:
      return import('@Modals/change-plan-modal/change-plan-modal');
    case MODALS_IDS.FANCYTEXT_MODAL:
      return import('@Modals/fancytext-modal/fancytext-modal');
    case MODALS_IDS.UPLOAD_FONT_AGREEMENT_MODAL:
      return import('@Modals/upload-font-agreement-modal/upload-font-agreement-modal');
    case MODALS_IDS.PMW_STOCK_UPLOAD_FAILED_MODAL:
      return import('@Modals/pmw-stock-upload-failed-modal/pmw-stock-upload-failed-modal');
    case MODALS_IDS.USER_MEDIA_MODAL:
      return import('@Modals/user-media-modal/user-media-modal');
    case MODALS_IDS.SHARE_COLLECTION_MODAL:
      return import('@Modals/share-collection-modal/share-collection-modal');
    case MODALS_IDS.SOCIAL_POSTS_DIALOG_MODAL:
      return import('@Modals/social-posts-dialog-modal/social-posts-dialog-modal');
    case MODALS_IDS.STOCK_MODAL:
      return import('@Modals/stock-modal/stock-modal');
    case MODALS_IDS.RESUMABLE_STOCK_MODAL:
      return import('@Modals/resumable-stock-modal/resumable-stock-modal');
    case MODALS_IDS.SOCIAL_MEDIA_WIZARD_MOBILE_FOOTER_MODAL:
      return import('@Modals/social-media-wizard-mobile-footer-modal/social-media-wizard-mobile-footer-modal');
    case MODALS_IDS.SOCIAL_ACCOUNTS_CONNECTION_MODAL:
      return import('@Modals/social-accounts-connection-modal/social-accounts-connection-modal');
    case MODALS_IDS.MOVE_MULTIMEDIA_MODAL:
      return import('@Modals/move-multimedia-modal/move-multimedia-modal');
    case MODALS_IDS.POSTER_EDITOR_ADD_ITEM_MODAL:
      return import('@Modals/poster-add-items-modal/poster-add-items-modal');
    case MODALS_IDS.SOCIAL_MEDIA_RESIZE_OPTIONS:
      return import('@Components/modals/social-media-resize-modal/social-media-resize-modal');
    case MODALS_IDS.MY_STUFF_MOBILE_NAVIGATION_MODAL:
      return import('@Modals/mystuff-mobile-navigation-options-modal/mystuff-mobile-navigation-options-modal');
    case MODALS_IDS.REPLACE_MEDIA_MODAL:
      return import('@Modals/replace-media-modal/replace-media-modal');
    case MODALS_IDS.REPLACE_BACKGROUND_IMAGE_MODAL:
      return import('@Modals/replace-background-image-modal/replace-background-image-modal');
    case MODALS_IDS.POSTER_EDITOR_GRID_MODAL:
      return import('@Modals/poster-editor-grid-modal/poster-editor-grid-modal');
    case MODALS_IDS.POSTER_EDITOR_TITLE_MODAL:
      return import('@Modals/poster-editor-title-modal/poster-editor-title-modal');
    case MODALS_IDS.POSTER_EDITOR_INTRO_ANIMATION_MODAL:
      return import('@Modals/poster-editor-intro-animation-modal/poster-editor-intro-animation-modal');
    case MODALS_IDS.POSTER_EDITOR_FOLDS_MODAL:
      return import('@Modals/poster-editor-folds-modal/poster-editor-folds-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_POSITION_MODAL:
      return import('@Modals/poster-editor-item-position-modal/poster-editor-item-position-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_OPACITY_MODAL:
      return import('@Modals/poster-editor-item-opacity-modal/poster-editor-item-opacity-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_FONT_SIZE_MODAL:
      return import('@Modals/poster-editor-item-font-size-modal/poster-editor-item-font-size-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_FONT_STYLE_MODAL:
      return import('@Modals/poster-editor-item-font-style-modal/poster-editor-item-font-style-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_FONT_STYLE_2_MODAL:
      return import('@Modals/poster-editor-item-style-2-modal/poster-editor-item-style-2-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_SPELLCHECK_MODAL:
      return import('@Modals/poster-editor-item-spellcheck-modal/poster-editor-item-spellcheck-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_HYPERLINK_MODAL:
      return import('@Modals/poster-editor-item-hyperlink-modal/poster-editor-item-hyperlink-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_ALIGNMENT_MODAL:
      return import('@Modals/poster-editor-item-alignment-modal/poster-editor-item-alignment-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_SHADOW_MODAL:
      return import('@Modals/poster-editor-item-shadow-modal/poster-editor-item-shadow-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_FLIP_MODAL:
      return import('@Modals/poster-editor-item-flip-modal/poster-editor-item-flip-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_STRETCH_MODAL:
      return import('@Modals/poster-editor-item-stretch-modal/poster-editor-item-stretch-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_ADJUSTMENTS_MODAL:
      return import('@Modals/poster-editor-item-adjustments-modal/poster-editor-item-adjustments-modal');
    case MODALS_IDS.POSTER_EDITOR_TEXT_FILL_MODAL:
      return import('@Modals/poster-editor-text-fill-modal/poster-editor-text-fill-modal');
    case MODALS_IDS.POSTER_EDITOR_LAYOUT_FILL_MODAL:
      return import('@Modals/poster-editor-layout-fill-modal/poster-editor-layout-fill-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_FILTERS_MODAL:
      return import('@Modals/poster-editor-item-filters-modal/poster-editor-item-filters-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_BACKGROUND_MODAL:
      return import('@Modals/poster-editor-item-background-modal/poster-editor-item-background-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_EFFECTS_MODAL:
      return import('@Modals/poster-editor-item-effects-modal/poster-editor-item-effects-modal');
    case MODALS_IDS.POSTER_EDITOR_TEXT_OUTLINE_MODAL:
      return import('@Modals/poster-editor-text-outline-modal/poster-editor-text-outline-modal');
    case MODALS_IDS.POSTER_EDITOR_QR_CONTENT_MODAL:
      return import('@Modals/poster-editor-qr-content-modal/poster-editor-qr-content-modal');
    case MODALS_IDS.POSTER_EDITOR_QR_CONTENT_COLOR_MODAL:
      return import('@Modals/poster-editor-qr-content-color-modal/poster-editor-qr-content-color-modal');
    case MODALS_IDS.POSTER_EDITOR_QR_CONTENT_BACKGROUND_MODAL:
      return import('@Modals/poster-editor-qr-content-background-modal/poster-editor-qr-content-background-modal');
    case MODALS_IDS.POSTER_EDITOR_VECTOR_BORDER_MODAL:
      return import('@Modals/poster-editor-vector-border-modal/poster-editor-vector-border-modal');
    case MODALS_IDS.POSTER_EDITOR_VECTOR_FILL_MODAL:
      return import('@Modals/poster-editor-vector-fill-modal/poster-editor-vector-fill-modal');
    case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_MODAL:
      return import('@Modals/poster-editor-fancy-text-modal/poster-editor-fancy-text-modal');
    case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_EFFECT_MODAL:
      return import('@Modals/poster-editor-fancy-text-effect-modal/poster-editor-fancy-text-effect-modal');
    case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_FONT_MODAL:
      return import('@Modals/poster-editor-fancy-text-fonts-modal/poster-editor-fancy-text-fonts-modal');
    case MODALS_IDS.POSTER_EDITOR_DRAW_BRUSH_MODAL:
      return import('@Modals/poster-editor-draw-brush-modal/poster-editor-draw-brush-modal');
    case MODALS_IDS.POSTER_EDITOR_DRAW_BRUSH_SIZE_MODAL:
      return import('@Modals/poster-editor-draw-brush-size-modal/poster-editor-draw-brush-size-modal');
    case MODALS_IDS.POSTER_EDITOR_DRAW_BRUSH_COLOR_MODAL:
      return import('@Modals/poster-editor-draw-brush-color-modal/poster-editor-draw-brush-color-modal');
    case MODALS_IDS.POSTER_EDITOR_DRAWING_COLOR_MODAL:
      return import('@Modals/poster-editor-drawing-color-modal/poster-editor-drawing-color-modal');
    case MODALS_IDS.POSTER_EDITOR_MORE_OPTIONS_MODAL:
      return import('@Modals/poster-editor-more-options-modal/poster-editor-more-options-modal');
    case MODALS_IDS.POSTER_EDITOR_NUMBER_OF_TABS_MODAL:
      return import('@Modals/poster-editor-number-of-tabs-modal/poster-editor-number-of-tabs-modal');
    case MODALS_IDS.POSTER_EDITOR_TABS_TEXT_MODAL:
      return import('@Modals/poster-editor-tabs-text-modal/poster-editor-tabs-text-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_LAYOUT_MODAL:
      return import('@Modals/poster-editor-item-layout-modal/poster-editor-item-layout-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_DATE_STYLES_MODAL:
      return import('@Modals/poster-editor-item-date-styles-modal/poster-editor-item-date-styles-modal');
    case MODALS_IDS.POSTER_EDITOR_LAYOUT_ITEM_BORDER_MODAL:
      return import('@Modals/poster-editor-layout-item-border-modal/poster-editor-layout-item-border-modal');
    case MODALS_IDS.POSTER_EDITOR_LAYOUT_ITEM_SPACING_MODAL:
      return import('@Modals/poster-editor-layout-item-spacing-modal/poster-editor-layout-item-spacing-modal');
    case MODALS_IDS.POSTER_EDITOR_MENU_ITEM_ICON_STYLES_MODAL:
      return import('@Modals/poster-editor-menu-item-icon-styles-modal/poster-editor-menu-item-icon-styles-modal');
    case MODALS_IDS.POSTER_EDITOR_SLIDESHOW_APPLY_PROPERTIES_MODAL:
      return import('@Modals/poster-editor-slideshow-apply-properties-modal/poster-editor-slideshow-apply-properties-modal');
    case MODALS_IDS.POSTER_EDITOR_SLIDESHOW_SLIDES_MODAL:
      return import('@Modals/poster-editor-slideshow-slides-modal/poster-editor-slideshow-slides-modal');
    case MODALS_IDS.POSTER_EDITOR_NAV_MORE_OPTIONS_MODAL:
      return import('@Modals/poster-editor-nav-more-options-modal/poster-editor-nav-more-options-modal');
    case MODALS_IDS.POSTER_EDITOR_LANGUAGE_MODAL:
      return import('@Modals/poster-editor-language-modal/poster-editor-language-modal');
    case MODALS_IDS.POSTER_EDITOR_TEXT_ITEM_FONT_MODAL:
      return import('@Modals/poster-editor-text-item-font-modal/poster-editor-text-item-font-modal');
    case MODALS_IDS.POSTER_EDITOR_BLEED_MODAL:
      return import('@Modals/poster-editor-bleed-modal/poster-editor-bleed-modal');
    case MODALS_IDS.POSTER_EDITOR_LAYOUT_ITEM_FONT_MODAL:
      return import('@Modals/poster-editor-layout-item-font-modal/poster-editor-layout-item-font-modal');
    case MODALS_IDS.POSTER_EDITOR_SLIDESHOW_TRANSITION_MODAL:
      return import('@Modals/poster-editor-slideshow-transition-modal/poster-editor-slideshow-transition-modal');
    case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_SHAPE_MODAL:
      return import('@Modals/poster-editor-fancytext-shape-modal/poster-editor-fancytext-shape-modal');
    case MODALS_IDS.POSTER_EDITOR_TAB_ITEM_SEPARATOR_MODAL:
      return import('@Modals/poster-editor-tab-item-separator-modal/poster-editor-tab-item-separator-modal');
    case MODALS_IDS.POSTER_EDITOR_FANCY_TEXT_COLORS_MODAL:
      return import('@Modals/poster-editor-fancy-text-colors-modal/poster-editor-fancy-text-colors-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_LIST_MODAL:
      return import('@Modals/poster-editor-item-list-modal/poster-editor-item-list-modal');
    case MODALS_IDS.POSTER_EDITOR_BACKGROUND_TYPE_MODAL:
      return import('@Modals/poster-editor-background-type-modal/poster-editor-background-type-modal');
    case MODALS_IDS.DURATION_DROPDOWN_MODAL:
      return import('@Modals/duration-dropdown-modal/duration-dropdown-modal');
    case MODALS_IDS.EDIT_AUDIO_ITEM_MODAL:
      return import('@Modals/edit-audio-item-modal/edit-audio-item-modal');
    case MODALS_IDS.POSTER_EDITOR_EXPORT_MODAL:
      return import('@Modals/poster-editor-export-modal/poster-editor-export-modal');
    case MODALS_IDS.POSTER_EDITOR_ADD_SLIDESHOW_MODAL:
      return import('@Modals/poster-editor-add-slide-modal/poster-editor-add-slide-modal');
    case MODALS_IDS.EMAIL_METRICS_DOWNLOAD_PDF_MODAL:
      return import('@Modals/email-metrics-download-pdf-modal/email-metrics-download-pdf-modal');
    case MODALS_IDS.EMBED_VIDEOS_EMAILMAKER_MODAL:
      return import('@Modals/embed-videos-emailmaker-input-modal/embed-videos-emailmaker-input-modal');
    case MODALS_IDS.ADD_FANCYTEXT_COLOR_MODAL:
      return import('@Modals/add-fancytext-color-modal/add-fancytext-color-modal');
    case MODALS_IDS.POSTER_EDITOR_EDIT_TEXT_MODAL:
      return import('@Modals/poster-editor-edit-text-modal/poster-editor-edit-text-modal');
    case MODALS_IDS.MY_STUFF_MOBILE_FOLDER_OPTIONS:
      return import('@Modals/mobile-mystuff-folder-options-modal/mobile-mystuff-folder-options-modal');
    case MODALS_IDS.EMBEDDED_EDITOR_CONSENT_MODAL:
      return import('@Modals/embedded-editor-consent-modal/embedded-editor-consent-modal');
    case MODALS_IDS.POSTER_EDITOR_GUIDES_MODAL:
      return import('@Modals/poster-editor-guides-modal/poster-editor-guides-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_LETTER_SPACING_MODAL:
      return import('@Modals/poster-editor-item-letter-spacing-modal/poster-editor-item-letter-spacing-modal');
    case MODALS_IDS.POSTER_EDITOR_TRANSCRIPT_LETTER_CASE_MODAL:
      return import('@Modals/poster-editor-transcript-letter-case-modal/poster-editor-transcript-letter-case-modal');
    case MODALS_IDS.POSTER_EDITOR_ITEM_LINE_HEIGHT_MODAL:
      return import('@Modals/poster-editor-item-line-height-modal/poster-editor-item-line-height-modal');
    case MODALS_IDS.POSTER_EDITOR_REPLACE_AUDIO_MODAL:
      return import('@Modals/poster-editor-replace-audio-modal/poster-editor-replace-audio-modal');
    case MODALS_IDS.RECORD_IMAGE_AND_VIDEO_MODAL:
      return import('@Modals/record-image-and-video-modal/record-image-and-video-modal');
    case MODALS_IDS.RECORD_AUDIO_MODAL:
      return import('@Modals/record-audio-modal/record-audio-modal');
    case MODALS_IDS.CONNECT_SSO_ACCOUNT_MODAL:
      return import('@Modals/connect-sso-account-modal/connect-sso-account-modal');
    case MODALS_IDS.SOCIAL_POST_SELECT_DESIGN_MODAL:
      return import('@Modals/social-post-select-design-modal/social-post-select-design-modal');
    case MODALS_IDS.SOCIAL_MEDIA_SELECT_COVER_MODAL:
      return import('@Modals/social-media-select-cover-modal/social-media-select-cover-modal');
    case MODALS_IDS.MEDIA_TYPE_MODAL:
      return import('@Modals/media-type-modal/media-type-modal/media-type-modal');
    case MODALS_IDS.AUDIENCE_EDIT_INFO_MODAL:
      return import('@Components/email-marketing-campaign-audience/components/audience-edit-info-modal/audience-edit-info-modal');
    case MODALS_IDS.SETTINGS_MODAL:
      return import('@Modals/settings-modal/settings-modal');
    case MODALS_IDS.PREMIUM_AGREEMENT_MODAL:
      return import('@Modals/premium-agreement-modal/premium-agreement-modal');
    case MODALS_IDS.AUTHENTICATE_MODAL:
      return import('@Modals/authenticate-modal/authenticate-modal');
    case MODALS_IDS.TWO_FACTOR_AUTHENTICATION_VERIFICATION_MODAL:
      return import('@Modals/two-factor-authentication-verification-modal/two-factor-authentication-verification-modal');
    case MODALS_IDS.PASSWORD_VERIFICATION_MODAL:
      return import('@Modals/password-verification-modal/password-verification-modal');
    case MODALS_IDS.MANAGE_TWO_FACTOR_AUTHENTICATION_MODAL:
      return import('@Modals/manage-two-factor-authentication-modal/manage-two-factor-authentication-modal');
    case MODALS_IDS.BRAND_ACTIVITY_MODAL:
      return import('@Modals/brand-activity-log-modal/brand-activity-log-modal');
    case MODALS_IDS.BRAND_COLOR_MODAL:
      return import('@Modals/brand-color-modal/brand-color-modal');
    case MODALS_IDS.PUBLISHING_OPTIONS_MODAL:
      return import('@Modals/publishing-options-modal/publishing-options-modal');
    case MODALS_IDS.EDIT_POSTER_SUBTITLES_MODAL:
      return import('@Modals/edit-poster-subtitles-modal/edit-poster-subtitles-modal');
    case MODALS_IDS.EDIT_POSTER_SUBTITLE_MORE_OPTIONS_MODAL:
      return import('@Modals/edit-poster-subtitle-more-options-modal/edit-poster-subtitle-more-options-modal');
    case MODALS_IDS.AI_TEXT_PROMPT_BOX_MODAL:
      return import('@Modals/ai-text-prompt-box-modal/ai-text-prompt-box-modal');
    case MODALS_IDS.TIMELINE_MODAL:
      return import('@Modals/timeline-modal/timeline-modal');
    case MODALS_IDS.ACCOUNT_DELETION_MODAL:
      return import('@Modals/account-deletion-modal/account-deletion-modal');
    case MODALS_IDS.AI_SUBTITLES:
      return import('@Modals/ai-subtitle-modal/ai-subtitle-modal');
    case MODALS_IDS.AI_SUBTITLE_GENERATION_LANGUAGE_SELECTOR_MODAL:
      return import('@Modals/ai-subtitle-generation-language-selector-modal/ai-subtitle-generation-language-selector-modal');
    case MODALS_IDS.POSTER_EDITOR_TRANSCRIPT_ACTIVE_WORD_MODAL:
      return import('@Modals/poster-editor-transcript-active-word-modal/poster-editor-transcript-active-word-modal');
    case MODALS_IDS.TRANSCRIPT_PRESETS_MODAL:
      return import('@Modals/transcript-presets-modal/transcript-presets-modal');
    case MODALS_IDS.POSTER_EDITOR_TRANSCRIPT_OUTLINE_MODAL:
      return import('@Modals/poster-editor-transcript-outline-modal/poster-editor-transcript-outline-modal');
    case MODALS_IDS.POSTER_EDITOR_TRANSCRIPT_TEXT_FILL_MODAL:
      return import('@Modals/poster-editor-transcript-text-fill-modal/poster-editor-transcript-text-fill-modal');
    case MODALS_IDS.GOOGLE_MAP_SELECTOR_MODAL:
      return import('@Modals/google-map-selector-modal/google-map-selector-modal');
    case MODALS_IDS.EMAIL_CAMPAIGN_DIALOG_MODAL:
      return import('@Modals/email-campaign-dialog-modal/email-campaign-dialog-modal');
    case MODALS_IDS.HOMEPAGE_AI_FEATURES_MODAL:
      return import('@Modals/homepage-ai-features-modal/homepage-ai-features-modal');
    case MODALS_IDS.HOMEPAGE_START_CREATING_MODAL:
      return import('@Modals/homepage-start-creating-modal/homepage-start-creating-modal');
    case MODALS_IDS.HOMEPAGE_CREATE_OPTIONS_MODAL:
      return import('@Modals/homepage-create-options-modal/homepage-create-options-modal');
    case MODALS_IDS.POSTER_EDITOR_RECTANGLE_ROUNDING_MODAL:
      return import('@Modals/poster-editor-rectangle-rounding-modal/poster-editor-rectangle-rounding-modal');
    case MODALS_IDS.BRAND_KIT_UPDATE_FROM_COLOR_PALETTE_MODAL:
      return import('@Modals/brand-kit-update-from-color-palette-modal/brand-kit-update-from-color-palette-modal');
    case MODALS_IDS.POSTER_EDITOR_ROUNDNESS_EFFECT_MODAL:
      return import('@Modals/poster-editor-roundness-effect-modal/poster-editor-roundness-effect-modal');
    case MODALS_IDS.ADD_MAILING_LIST_MODAL:
      return import('@Modals/add-mailing-list-modal/add-mailing-list-modal');
    case MODALS_IDS.CREATE_SIGNUP_FORM_MODAL:
      return import('@Modals/create-signup-form-modal/create-signup-form-modal');
    case MODALS_IDS.EMAIL_CAMPAIGN_PUBLISH_OPTIONS_MODAL:
      return import('@Modals/email-campaign-publish-options-modal/email-campaign-publish-options-modal');
    case MODALS_IDS.BUSINESS_PROFILES_HOURS_MODAL:
      return import('@Modals/business-profiles-hours-modal/business-profiles-hours-modal');
    case MODALS_IDS.BUSINESS_PROFILES_ACTIVITY_MODAL:
      return import('@Modals/business-profiles-activity-modal/business-profiles-activity-modal');
    case MODALS_IDS.GOOGLE_BUSINESS_PROFILES_IMPORTED_MODAL:
      return import('@Modals/google-business-profiles-imported-modal/google-business-profiles-imported-modal');
    case MODALS_IDS.EMAIL_CAMPAIGN_SENDING_OPTIONS_MODAL:
      return import('@Modals/email-campaign-sending-options-modal/email-campaign-sending-options-modal');
    case MODALS_IDS.SOCIAL_ACCOUNT_CONNECTION_RESPONSE_MODAL:
      return import('@Modals/social-account-connection-response-modal/social-account-connection-response-modal');
    case MODALS_IDS.START_CREATING_MODAL:
      return import('@Modals/start-creating-modal/start-creating-modal');

    default:
      throw new Error(`No module found for modal ID: ${modalId}`);
  }
};
